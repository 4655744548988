<template>
  <b-dropdown
    v-if="(userRoles && userRoles.length) || user?.roles.includes('attendance-events-manager')"
    position="is-bottom-right"
    append-to-body
    aria-role="menu"
  >
    <fw-button-dropdown
      slot="trigger"
      type="link-black"
      :size="$device.isWDesktop() ? 'md' : 'sm'"
      class="flex flex-col"
      :label="isInManagment ? $t('managment') : $t('personal')"
      :upper-label="$device.isWDesktop() ? $t('area') : null"
    >
    </fw-button-dropdown>
    <b-dropdown-item
      class="flex gap-1 items-center font-medium"
      :label="$t('personal')"
      aria-role="listitem"
      :class="{ 'text-primary': !isInManagment }"
      @click.native="goToPublic"
    >
      {{ $t('personal') }}
    </b-dropdown-item>
    <b-dropdown-item
      :label="$t('managment')"
      aria-role="listitem"
      class="flex gap-1 items-center font-medium"
      :class="{ 'text-primary': isInManagment }"
      @click.native="goToManage"
    >
      {{ $t('managment') }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.getters.getUser
    },

    userRoles() {
      return this.$store.getters.getUserPermissions
    },

    isInManagment() {
      return this.$route.name.includes('manage')
    }
  },

  methods: {
    goToPublic() {
      if (this.isInManagment) {
        this.$router.push({ name: 'home' })
      }
    },

    goToManage() {
      if (!this.isInManagment) {
        this.$router.push({ name: 'manage' })
      }
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "personal": "Pessoal",
    "managment": "Gestão",
    "area": "Área"
  },
  "en": {
    "personal": "Personal",
    "managment": "Managment",
    "area": "Area"
  }
}
</i18n>
